import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Link from "gatsby-link";

import styles from "./menu.module.scss";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Menu | {data.site.siteMetadata.title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="View the Fume Restobar Menu. Fume RestoBar is now open in Holmedale in Brantford!" />
        <meta property="og:title" content={`Menu | ${data.site.siteMetadata.title}`} />
        <meta property="og:description" content="View the Fume Restobar Menu. Fume RestoBar is now open in Holmedale in Brantford!" />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`Menu | ${data.site.siteMetadata.title}`} />
        <meta name="twitter:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
      </Helmet>

      {data.allWordpressPage.edges.map(({ node }) => (
        <div className={styles.menu} key={node.title}>
          <div className={styles.topMenu}>
            <ul className={styles.anchors}>
              {node.acf.meals.map((meal) => {
                return meal.hide_meal ? "" : <li key={meal.meal_title.split(' ').join('-').toLowerCase()}>
                  <Link
                    to={"/menu#"+meal.meal_title.split(' ').join('-').toLowerCase()}
                  >
                    {meal.meal_title}
                  </Link>
                </li>
              })}
            </ul>
          </div>

          {node.acf.meals.map((meal) => {
            return meal.hide_meal ? "" : <div key={meal.meal_title.split(' ').join('-')}>
              <h2 id={meal.meal_title.split(' ').join('-').toLowerCase()}><span>{meal.meal_title}</span></h2>
              {meal.meal_details !== "" ? <p className={styles.mealDetails}>{meal.meal_details}</p> : ""}
              {meal.meal_subcat && meal.meal_subcat.map((subcat) =>
                <div className={styles.subcat} key={meal.meal_subcat}>
                  {subcat.subcategory_name !== "" ? <h3>{subcat.subcategory_name}</h3> : ``}
                  {subcat.menu_item.map((menuitem) =>
                    <div className={styles.menuItem} key={menuitem.item_name.split(' ').join('-')}>
                      <div className={styles.menuTitle}>
                        {menuitem.item_name}{menuitem.dietary_info.length > 0 ? ` - (${menuitem.dietary_info})` : ''}
                      </div>
                      <div className={styles.menuDesc}>
                        {menuitem.item_description ? `${menuitem.item_description}` : ''}
                      </div>
                      {menuitem.available_addons &&
                        menuitem.addon.map((addon) =>
                          <div className={styles.addonItem} key={addon.addon_item.split(' ').join('-')}>{addon.addon_item}: <strong>${addon.addon_price}</strong></div>
                        )
                      }
                      <div className={styles.menuPrice}>{menuitem.price ? `$${menuitem.price}` : ''}</div>
                    </div>
                  )}

                </div>
              )}
            </div>
          })}

          <div className={styles.content} dangerouslySetInnerHTML={{__html: node.content }} />

          <div className={styles.legend}>
            <strong>Legend</strong><br />
            GF = Gluten Free<br />
            Veg = Vegetarian<br />
            Vegan = Vegan<br />
            DF = Dairy Free
          </div>
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        siteUrl
      }
    },
    allWordpressPage(
      filter: { slug:{ eq: "menu" }}
    ) {
       edges {
         node {
          title,
          slug,
          acf {
          meals {
            meal_title,
            hide_meal,
            meal_details,
            meal_subcat {
              subcategory_name
              menu_item {
                item_name
                item_description
                price,
                dietary_info,
                available_addons,
                addon {
                  addon_item
                  addon_price
                }
              }
            }
          }
        }
        }
      }
    }
  }
`
